// Default theme
import { theme as stitchTheme } from "gatsby-theme-stitch";
import ms from "modularscale-js"

// const minorSecond   = 16/15;
// const majorSecond   = 1.125;
// const minorThird    = 1.2;
const majorThird    = 1.25;
// const perfectFourth = 4/3;
// const augFourth     = 1.414;
// const perfectFifth  = 1.5;
// const minorSixth    = 1.6;
// const goldenSection = 1.61803398875;
// const majorSixth    = 5/3;
// const minorSeventh  = 16/9;
// const majorSeventh  = 1.875;
// const octave        = 2;
// const majorTenth    = 2.5;
// const majorEleventh = 8/3;
// const majorTwelfth  = 3;
// const doubleOctave  = 4;

const msConfig = {
   base: [18], ratio: majorThird
}

export const theme = {

  // all tailwind styles
  ...stitchTheme,

  fontSizes: [
    ms(0, msConfig),
    ms(1, msConfig),
    ms(2, msConfig),
    ms(3, msConfig),
    ms(4, msConfig),
    ms(5, msConfig),
  ],

  breakpoints: [ '640px', '768px', '1025px', '1280px' ],

  // colors
  colors: {

    ...stitchTheme.colors,

    // primary: '#F37949',
    // primaryHover: '#DE3828',
    // secondary: '#f3496e',
    // boldOcur: '#49c3f3',
    boldOcur: '#f9be30',
    primaryHover: '#f16559',
    secondary: '#2f6f53',
    primary: '#f16559',

    // // text: `#1d1d1d`
    text: '#4A5568',
    adBannerBg: '#2c5e7a',

    boxBg1: '#2f6f53',
    boxBg2: '#f16559',
    boxBg3: '#2c5e7a'
  },

  layout: {
      ...stitchTheme.layout,
      container: {
        ...stitchTheme.layout.container,
        // p: [2, 2, 4],
      },
  },

  global: {
      ...stitchTheme.global,

      html: {
        ...stitchTheme.html,
        scrollBehavior: `smooth`,
      },

      body: {
        ...stitchTheme.global.body,


      },

      "section:nth-of-type(even)": {
        background: stitchTheme.colors.gray[1]
      }
  },
  
  fonts: {
    body: 'Barlow',
    heading: 'Barlow Condensed'
  },

  text: {
    h1: {
       fontSize: 12 
    }
  },


  styles: {

    ...stitchTheme.styles,

    label: {
      fontFamily: `body`,
      fontWeight: `light`,
      fontSize: 1,
    },


    h1: {
      ...stitchTheme.styles.h1,
      py: [1, 2],
      px: 0,
      color: `gray.7`,
      fontSize: [3, 3, 4, 5],
    },

    h2: {
      ...stitchTheme.styles.h2,
      py: [1, 2],
      color: `gray.7`,
      fontSize: [2, 2, 3, 4],
    },

    h3: {
      ...stitchTheme.styles.h3,
      py: [1, 1],
      color: `gray.7`,
      fontSize: [1, 1, 2, 3],
    },

    p: {
      ...stitchTheme.styles.p,
      lineHeight: [`24px`, `30px`],
      // fontSize: [1, 2],
      fontSize: 0,
      img: {
        width: `auto`,
        maxWidth: `100%`
      },
      strong: {
        bg: 'white',
        px: 2,
        color: 'primary'
      },
      em: {
        bg: 'secondary',
        fontStyle: 'normal',
        px: 0,
        color: 'white'
      },
      th: {
        fontFamily: 'body',
      },
      td: {
        fontFamily: 'body',
      },
    },

    blockquote: {
      fontStyle: `italic`,
      backgroundColor: `gray.1`,
      borderLeftWidth: `4px`,
      borderLeftStyle: `solid`,
      borderLeftColor: `primaryHover`,
      p: 3,
      mx: 0,
      my: 1,
    },

    ul : {
      listStyleType: `none`,
      p: `0px`
    },

    ol : {
      listStyleType: `none`,
      p: `0px`
    },

    li: {
      fontFamily: `body`,
      fontSize: 0,
      color: `text`,
      lineHeight: [`24px`, `30px`],
      pb: 2,
      marginLeft: '1em',
      listStyleImage: 'url("/images/arrow.svg")',
    },

    pre: {
      code: {
        display: `none`
      },
    },

    a: {
      ...stitchTheme.styles.a,
      fontFamily: `body`,
      "&:link, &:visited": {
        textDecoration: `none`,
        color: `primaryHover`,
      }
    },

    Hero1: {
       backgroundColor: `primary`,
       variant: 'styles.inverted',
       buttons: {
         simple: {
           fontFamily: `body`,
           variant: `buttons.elevated`,
           borderColor: `white`,
           boxShadow: `none`,
           "&:hover": {
             variant: `buttons.elevated.&:hover`,
             color: `text`,
           }
         }
       },
       p: {
         color: `white !important`
       }
    },


    Brand: {
      ...stitchTheme.styles.Brand,
      a: {
        ...stitchTheme.styles.Brand.a,
        "&:link, &:visited": {
          textDecoration: `none`,
          color: `inherit`
        }
      }
    },

    Footer: {
      ...stitchTheme.styles.Footer,
      // backgroundColor: `white`,
      fontFamily: 'body',
      color: `gray.9`,
      borderTopWidth: `1px`,
      borderTopStyle: `solid`,
      borderTopColor: `gray.3`,
      a: {
        ...stitchTheme.styles.Brand.a,
        color: `gray.7`,
        fontWeight: `400`,
        "&:link, &:visited": {
          textDecoration: `none`,
          color: `gray.7`
        }
      }
    },

    Footer2: {
      ...stitchTheme.styles.Footer,
      // backgroundColor: `white`,
      fontFamily: 'body',
      color: `gray.9`,
      borderBottomWidth: `1px`,
      borderBottomStyle: `solid`,
      borderBottomColor: `gray.2`,
      a: {
        ...stitchTheme.styles.Brand.a,
        color: `gray.7`,
        fontWeight: `400`,
        "&:link, &:visited": {
          textDecoration: `none`,
          color: `gray.7`
        }
      }
    },

    Header: {
      ...stitchTheme.styles.Header,
      // borderBottomWidth: `1px`,
      // borderBottomStyle: `solid`,
      // borderBottomColor: `gray.2`,
    },

    Navigation: {
      ...stitchTheme.styles.Navigation,
      a: {
        fontFamily: 'heading',
        fontWeight: `bold`,
        // ...stitchTheme.styles.Navigation.a,
        fontSize: 0,
        // ml: 3,
        borderBottomWidth: `1px`,
        borderBottomStyle: `solid`,
        borderBottomColor: `white`,
        textTransform: `uppercase`,
        "&:hover": {
          borderBottomWidth: `1px`,
          borderBottomStyle: `solid`,
          borderBottomColor: `primary`,
        }
      },
    },

    Card5: {
      ...stitchTheme.styles.Card3,
      borderTopWidth: `3px`,
      borderTopStyle: `solid`,
      borderTopColor: `gray.3`,
      transition: `transform 420ms cubic-bezier(.165,.84,.44,1)`,
      "svg": {
        display: 'none',
      },
      "&:hover": {
        boxShadow: `md`,
        borderTopWidth: `3px`,
        borderTopColor: `primary`,
        transform: `scale(1.05)`,
        p: {
          color: `primary`,
          fontWeight: `semibold`
        },
      }
    },  
    
    Masonry: {
      // only color hashes or css colors for background - not colors in this
      // theme file
      background: 'transparent',
      Box: {
        color: '#F9F8F8',
        bg: ['#2f6f53', '#f16559', '#2c5e7a']
      }
    },

    Timeline: {
      // only color hashes or css colors for background - not colors in this
      // theme file
      background: 'transparent',
      Box: {
        color: 'gray.1',
        bg: ['boxBg1', 'boxBg2', 'boxBg3']
      }
    }

  }
}

// assign root theme
theme.styles.root = {...theme.styles}

export default theme;
